import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblKomponenteTypCreateComponent } from '@app/views/stammdaten/tbl_komponente_typ/create/create.component';

@Component({
	//templateUrl: '../../../../common/templates/create/create.component.html',
	//styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	templateUrl: '../../../../../common/templates/create/create.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleReplaceComponentDialogComponent extends CreateComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblKomponente';
		this.name = 'MENU.KOMPONENTE';
		this.url = '/' + PATH.KOMPONENTE;
		this.fieldgroups.push(
			[
				{ type: 'date', key: 'COMPONENT_LINK_FROM', required: false, width: 400, dateonly: true },
				{ type: 'text', key: 'serial_no', required: false, width: 400 },
				{ type: 'text', key: 'bemerkungen_ausbau', required: false, width: 400 },
				{ type: 'text', key: 'bemerkungen_einbau', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}

	getOptions(): void {
		super.getOptions();
	}

	/*
	 * override
	 */
	createEntry(): void {
		if(this.ref && this.entry) {
			this.ref.close({
				serial_no: this.entry['serial_no'],
				bemerkungen_ausbau: this.entry['bemerkungen_ausbau'],
				bemerkungen_einbau: this.entry['bemerkungen_einbau'],
				COMPONENT_LINK_FROM: this.entry['COMPONENT_LINK_FROM']
			});
		} else this.ref.close({isEmpty: true});
	}
}
